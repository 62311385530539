import image1 from '../assets/graphics/1.png';
import image10 from '../assets/graphics/10.png';
import image2 from '../assets/graphics/2.png';
import image3 from '../assets/graphics/3.png';
import image4 from '../assets/graphics/4.png';
import image5 from '../assets/graphics/5.png';
import image6 from '../assets/graphics/6.png';
import image7 from '../assets/graphics/7.png';
import image8 from '../assets/graphics/8.png';
import image9 from '../assets/graphics/9.png';
import image11 from '../assets/graphics/1_8.png';
import image12 from '../assets/graphics/E8.png';
import image13 from '../assets/graphics/christmas.png';
import image14 from '../assets/graphics/billboard.jpg';
import image15 from '../assets/graphics/bvc.png';
import image16 from '../assets/graphics/enli.png';
export const GraphicDesignArr = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16
 ];
