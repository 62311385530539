import { Box, Button, Modal } from "@mui/material";
import React from "react";
import { ImagesArr } from "../constants/Images";

const Photos = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ paddingTop: "8rem", justifyContent: "center" }}>
      <span
        className="section-title"
        style={{
          paddingBottom: "10px !important",
          paddingLeft: "15px",
          fontSize: "2.5rem",
        }}
      >
        Photos
      </span>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          gap: "20px",
          padding: "15px",
        }}
        style={{ justifyContent: "center", paddingBottom: "8rem" }}
      >
        {ImagesArr.map((image, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              width: "100%",
              aspectRatio: "1", // Ensures the image box is a square
              overflow: "hidden",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => handleOpen(image)}
          >
            <img
              src={image}
              alt={`Photo ${index + 1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </Box>
        ))}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "800px",
            maxHeight: "600px",
            overflow: "auto",
            backgroundColor: "white",
            padding: "20px",
            border: "1px solid #ccc",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "4px",
              maxWidth: "100%",
              maxHeight: "500px",
            }}
          />
          <Button
            onClick={handleClose}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Photos;
