import { useNavigate } from "react-router-dom";
import { SlideShow } from "../../components/Slideshow";
export const Intro = () => {
  const navigate = useNavigate();
  return (
    <div className="intro">
      {/* Left side: Text */}
      <div className="intro-text animate__animated animate__fadeInLeft">
        <h1>Welcome to our award-winning multimedia studio</h1>
        <p>
          Dedicated to creating impactful content for school districts across
          Texas. From engaging videos and photography to professional graphic
          design—including logos, print media, and promotional materials—we
          bring your vision to life. We also offer multimedia production
          training to help communication teams enhance their storytelling
          skills.
        </p>
        <p>
          Led by Santosh Vemula and a passionate team, we pride ourselves on
          providing a personalized, one-on-one experience. Our creative process
          ensures your district’s stories resonate with the heart of your
          community, leaving a lasting impact that inspires and connects.
        </p>
        <p>
          Recognized by the Texas School Public Relations Association (TSPRA)
          with awards such as two Crystal Commendation Awards, numerous Best of
          Category medals, and Gold Stars, we’ve also shared insights at TSPRA
          on maximizing efficiency in small communication teams to widespread
          acclaim.
        </p>
        <p>
          Trusted by districts like Bryan ISD and Leander ISD, our long-term
          partnerships reflect our commitment to quality and storytelling
          excellence. Ready to get started? Click the button below to connect
          with us today!
        </p>
        <button className="cta-button" onClick={() => navigate("/contact")}>
          Get in Touch
        </button>
      </div>

      {/* Right side: SlideShow */}
      <div className="slideshow-container">
        <SlideShow />
      </div>
    </div>
  );
};
