import image1 from "../assets/images/1.jpg";
import image2 from "../assets/images/2.jpg";
import image3 from "../assets/images/3.jpg";
import image4 from "../assets/images/4.jpg";

import image5 from "../assets/images/5.jpg";
import image6 from "../assets/images/6.jpg";
import image7 from "../assets/images/7.jpg";

    
import image10 from "../assets/images/10.jpg";
import image11 from "../assets/images/11.jpg";
import image12 from "../assets/images/12.jpg";
import image13 from "../assets/images/13.jpg";
import image8 from "../assets/images/8.jpg";
import image9 from "../assets/images/9.jpg";

import image14 from "../assets/images/14.jpg";
import image15 from "../assets/images/15.jpg";
import image16 from "../assets/images/16.jpg";

import image17 from "../assets/images/17.jpg";
import image18 from "../assets/images/18.jpg";
import image19 from "../assets/images/19.jpg";
import image20 from "../assets/images/20.jpg";
import image21 from "../assets/images/21.jpg";
import image22 from "../assets/images/22.jpg";
import image23 from "../assets/images/23.jpg";
import image24 from "../assets/images/24.jpg";
import image25 from "../assets/images/25.jpg";
import image26 from "../assets/images/26.jpg";
import image27 from "../assets/images/27.jpg";
import image28 from "../assets/images/28.jpg";
import image29 from "../assets/images/29.jpg";
import image30 from "../assets/images/30.jpg";

import BtsImage1 from '../assets/BTS/1.jpg';
import BtsImage3 from '../assets/BTS/3.jpg';
import BtsImage4 from '../assets/BTS/4.jpg';
import LogoImage1 from "../assets/graphics/1.png";
import LogoImage2 from "../assets/graphics/2.png";
import LogoImage3 from "../assets/graphics/3.png";
import LogoImage6 from "../assets/graphics/billboard.jpg";
import LogoImage5 from "../assets/graphics/bvc.png";
import LogoImage4 from "../assets/graphics/enli.png";
 


  import BtsImage10 from '../assets/BTS/10.jpg';
import BtsImage11 from '../assets/BTS/11.jpg';
import BtsImage12 from '../assets/BTS/12.jpg';
import BtsImage13 from '../assets/BTS/13.jpg';
import BtsImage14 from '../assets/BTS/14.jpg';
import BtsImage5 from '../assets/BTS/5.jpg';
import BtsImage6 from '../assets/BTS/6.jpg';
import BtsImage7 from '../assets/BTS/7.jpg';
import BtsImage8 from '../assets/BTS/8.jpg';
import BtsImage9 from '../assets/BTS/9.jpg';

import LogoImageE8 from '../assets/graphics/1_8.png';
import LogoImageE9 from '../assets/graphics/E8.png';
import LogoImage7 from '../assets/graphics/christmas.png';

export const ImagesArr = [
  image1,
  image2,
  image3,
  image6,
  image7,
  image4,
  image5,

  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,

  image26,
  image27,
  image28,
  image29,
  image30,
];
 


export const HomeImages = [
  image1,
  image2,
  image3,
  image9,
  image10,
  image11,
    image15,
  image12,
  

];


export const BTSImages = [BtsImage1, BtsImage3, BtsImage4, BtsImage5, BtsImage6, BtsImage7, BtsImage8, BtsImage9, BtsImage10, BtsImage11, BtsImage12, BtsImage13, BtsImage14];

export const LogosImagesRow0 = [LogoImageE8, LogoImageE9];

export const LogosImagesRow1 = [LogoImage1, LogoImage2, LogoImage3];

export const LogosImagesRow2 = [LogoImage4, LogoImage5];


export const LogosImagesRow3 = [LogoImage6, LogoImage7];

