import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../assets/logo.png";

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Function to handle hamburger menu toggle
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleVideoNavigation = () => {
    navigate("/videos"); // Use navigate instead of Navigate
  };

  const handlePhotoNavigation = () => {
    navigate("/photos"); // Use navigate instead of Navigate
  };

  const handleGraphicDesignNavigation = () => {
    navigate("/graphic-design"); // Use navigate instead of Navigate
  };

  const handleContactNavigation = () => {
    navigate("/contact"); // Use navigate instead of Navigate
  };
const handleHomeNavigation = () => {
  navigate("/");
}
  useEffect(() => { 
    const navbar = document.querySelector(".sticky-navbar");
    const handleScroll = () => {
      if (window.scrollY > 50) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="container main-menu">
      <div className="sticky-navbar">
        <div className="navbar-container">
          {/* Logo */}
          <div id="logo">
            <a onClick={handleHomeNavigation}>
              <img src={LogoIcon} alt="Logo" height={50} width={50} />
            </a>
          </div>

          {/* Hamburger Menu Icon (for mobile) */}
          <div className="hamburger-menu" onClick={toggleMenu}>
            <FaBars />
          </div>

          {/* Navigation Menu */}
          <nav id="nav-menu-container">
            <ul className={`nav-menu ${isMenuOpen ? "active" : ""}`}>
              <li>
                <a
                  className={`nav-link ${
                    window.location.pathname === "/" ? "highlight" : ""
                  }`}
                  onClick={handleHomeNavigation}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className={`nav-link ${
                    window.location.pathname === "/videos" ? "highlight" : ""
                  }`}
                  onClick={handleVideoNavigation}
                >
                  Videos
                </a>
              </li>
              <li>
                <a
                  className={`nav-link ${
                    window.location.pathname === "/photos" ? "highlight" : ""
                  }`}
                  onClick={handlePhotoNavigation}
                >
                  Photos
                </a>
              </li>
              <li>
                <a
                  className={`nav-link ${
                    window.location.pathname === "/graphic-design"
                      ? "highlight"
                      : ""
                  }`}
                  onClick={handleGraphicDesignNavigation}
                >
                  Graphic Design
                </a>
              </li>
              <li>
                <a
                  className={`nav-link ${
                    window.location.pathname === "/contact" ? "highlight" : ""
                  }`}
                  onClick={handleContactNavigation}
                >
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
