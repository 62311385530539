import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  TextField, useMediaQuery,
  useTheme
} from "@mui/material";
import emailjs from "emailjs-com";
import React, { useState } from "react";

export const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    clientEmail: "",
    phone: "",
    message: "",
    email: "santoshvemula01@gmail.com",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
const handleSubmit = (e) => {
  e.preventDefault();

  const serviceId = "service_1njeywp";
  const templateId = "template_0cf76jh";
  const userId = "Ptu99sP9DSQ3WNmEP";

  // Create the email data with all form fields
  const emailData = {
    from_name: "Santosh Vemula",
    from_email: "santoshvemula01@gmail.com",
    to_name: `${formData.firstName} ${formData.lastName}`,
    to_email: formData.clientEmail,
    message: formData.message,
    client_email: formData.clientEmail, // Add client email
    phone_number: formData.phone, // Add phone number
    first_name: formData.firstName, // Add individual name fields
    last_name: formData.lastName,
  };

  emailjs
    .send(serviceId, templateId, emailData, userId)
    .then(() => {
      setSnackbarMessage("Message sent successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Reset form data
      setFormData({
        firstName: "",
        lastName: "",
        clientEmail: "",
        phone: "",
        message: "",
        email: "santoshvemula01@gmail.com", // Reset to the static "From" email
      });
    })
    .catch((error) => {
      setSnackbarMessage("Failed to send message. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("EmailJS error:", error);
    });
};


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        height: isMobile ? "100vh" : "auto",
      
        color: "white",
        display: "flex",
        justifyContent: "center",
        padding: isMobile ? 2 : 4,
      }}
    >
      <Box
        sx={{
          width: isMobile ? "100%" : 600,
          maxWidth: "100%",
        }}
      >
    <section id="services" className="services">
      <h2 className="animate__animated animate__fadeInUp">Contact</h2>
        <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{
                  style: { color: "white", borderColor: "white" },
                }}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{
                  style: { color: "white", borderColor: "white" },
                }}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="clientEmail"
                type="email"
                value={formData.clientEmail}
                onChange={handleChange}
                required
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{
                  style: { color: "white", borderColor: "white" },
                }}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleChange}
                required
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{
                  style: { color: "white", borderColor: "white" },
                }}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                multiline
                rows={4}
                required
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{
                  style: { color: "white", borderColor: "white" },
                }}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                style={{ backgroundColor: "#e45447" }}
              >
                Send Message
              </Button>
            </Grid>
          </Grid>
          </form>
          
    </section>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};
