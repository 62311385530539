import { Box, Button, Modal } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { HomeImages } from "../../constants/Images";
const Photos = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
 const navigate = useNavigate(); 
  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section id="services" className="services">
      <h2 className="animate__animated animate__fadeInUp">Photos</h2>
      <div
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        {HomeImages.map((image, index) => (
          <Box display="flex" flexWrap="wrap" gap={2}>
            <img
              src={image}
              alt={`Photo ${index + 1}`}
              height={250}
              style={{
                width: "100%",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "10px",
              }}
              onClick={() => handleOpen(image)}
            />
          </Box>
        ))}
      </div>
      <Box display="flex" justifyContent="center" marginTop={3}>
        <button className="cta-button" onClick={() => navigate("/photos")}>
          {"See more"}
        </button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "800px",
            maxHeight: "600px",
            overflow: "auto",
            backgroundColor: "white",
            padding: "20px",
            border: "1px solid #ccc",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: "100%", borderRadius: "4px" }}
          />
          <Button
            onClick={handleClose}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </section>
  );
};


export default Photos;