import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { BTSImages } from "../constants/Images";

export const SlideShow = () => (
  <Carousel 
    autoPlay 
    showThumbs={false} 
    showIndicators={false} 
    infiniteLoop
    // Remove dynamicHeight to keep consistent height
    emulateTouch={true}
  >
    {BTSImages.map((image, index) => (
      <div
        key={index}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "500px", // Fixed height instead of maxHeight
          overflow: "hidden",
          width: "100%",
          position: "relative",
          backgroundColor: "#f5f5f5", // Light background for empty spaces
        }}
      >
        <img
          alt=""
          src={image}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            margin: "auto",
          }}
        />
      </div>
    ))}
  </Carousel>
);

