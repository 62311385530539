import { Box, Button, Modal } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import LargeLogo from "../../assets/graphics/6_large.png";
import {
  LogosImagesRow0,
  LogosImagesRow1,
  LogosImagesRow2,
  LogosImagesRow3,
} from "../../constants/Images";
const HomeLogos = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
 const navigate = useNavigate(); 
  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imageContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
    maxWidth: "100%",
    padding: "0 20px",
  };

  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
    objectFit: "contain",
    cursor: "pointer",
    borderRadius: "10px",
  };

  const boxStyle = {
    flex: "1 1 300px", // Base width of 300px but allows flexing
    maxWidth: "400px", // Maximum width for each image container
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <section id="services" className="services">
      <h2 className="animate__animated animate__fadeInUp"> Graphic Design</h2>
      
      {/* First Row */}
      <div className="logos-first-row" style={imageContainerStyle}>
        {LogosImagesRow0.map((image, index) => (
          <Box key={index} sx={boxStyle}>
            <img
              src={image}
              alt={`Photo ${index + 1}`}
              className="logo-image1"
              style={imageStyle}
              onClick={() => handleOpen(image)}
            />
          </Box>
        ))}
      </div>

      {/* Second Row */}
      <div className="logos-first-row" style={imageContainerStyle}>
        {LogosImagesRow1.map((image, index) => (
          <Box key={index} sx={boxStyle}>
            <img
              src={image}
              alt={`Photo ${index + 1}`}
              className="logo-image1"
              style={imageStyle}
              onClick={() => handleOpen(image)}
            />
          </Box>
        ))}
      </div>

      {/* Large Logo */}
      <div style={{ padding: "20px", maxWidth: "100%" }}>
        <img
          src={LargeLogo}
          className="gallery-image"
          style={{
            width: "100%",
            maxWidth: "100%",
            height: "auto",
            cursor: "pointer",
            borderRadius: "10px",
          }}
        />
      </div>

      {/* Third Row */}
      <div className="logos-first-row" style={imageContainerStyle}>
        {LogosImagesRow2.map((image, index) => (
          <Box key={index} sx={boxStyle}>
            <img
              src={image}
              alt={`Photo ${index + 1}`}
              className="gallery-image"
              style={imageStyle}
              onClick={() => handleOpen(image)}
            />
          </Box>
        ))}
      </div>

      {/* Fourth Row */}
      <div className="logos-first-row" style={{...imageContainerStyle, paddingTop: "20px"}}>
        {LogosImagesRow3.map((image, index) => (
          <Box key={index} sx={boxStyle}>
            <img
              src={image}
              alt={`Photo ${index + 1}`}
              className="gallery-image"
              style={imageStyle}
              onClick={() => handleOpen(image)}
            />
          </Box>
        ))}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "800px",
            maxHeight: "90vh",
            overflow: "auto",
            backgroundColor: "white",
            padding: "20px",
            border: "1px solid #ccc",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "80vh",
              objectFit: "contain",
              borderRadius: "4px",
            }}
          />
          <Button
            onClick={handleClose}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </div>
      </Modal>

      <Box display="flex" justifyContent="center" marginTop={3}>
        <button className="cta-button" onClick={() => navigate("/graphic-design")}>
          See more
        </button>
      </Box>
    </section>
  );
};

export default HomeLogos;
