import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Header } from "./components/Header";
import { Contact } from './screens/Contact';
import GraphicDesign from "./screens/GraphicDesign";
import { Home } from './screens/Home/Home';
import Photos from './screens/Photos';
import Videos from './screens/Videos';
const App = () => {
  return (
    <div>
          <Header />
      <MainContent />
    </div>
  );
};

const MainContent = () => (
  <div className="main-content">
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/photos" element={<Photos />} />
      <Route path="/graphic-design" element={<GraphicDesign />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  </div>
);

export default App;
