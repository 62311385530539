import React from "react";
import {
  FaCamera,
  FaChalkboardTeacher,
  FaPaintBrush,
  FaVideo,
  FaVrCardboard,
} from "react-icons/fa";

export default function Services() {
  return (
    <section id="services" className="services">
      <h2 className="animate__animated animate__fadeInUp">My Services</h2>
      <div className="service-list">
        {/* Photography Service */}
        <div className="service animate__animated animate__zoomIn">
          <FaCamera size={50} color="#e45447" className="service-icon" />
          <h3>Photography</h3>
          <ul>
            <li>Event Photography</li>
            <li>Football Games</li> <li>Athletics photoshoots</li>
            <li>Classroom activities and student engagement</li>
            <li> Staff Headshots</li>
            <li>Marketing and promotional imagery</li>
            <li>Aerial photography using drones</li>
          </ul>
        </div>

        {/* Videography Service */}
        <div className="service animate__animated animate__zoomIn">
          <FaVideo size={50} color="#e45447" className="service-icon" />
          <h3>Videography</h3>
          <ul>
            <li>Event coverage and highlight reels</li>
            <li>Athletics and sports promotional videos</li>
            <li>Live streaming (Single camera and multi-camera setup)</li>
            <li>Promotional campaigns for enrollment and programs</li>
            <li>Social media-ready video snippets</li>
            <li>Training and instructional videos</li>
            <li>Student and staff interviews</li>
            <li>School Program showcases</li>
            <li>Virtual tours and walkthroughs</li>
            <li>Aerial videography using drones</li>
          </ul>
        </div>

        {/* Graphic Design Service */}
        <div className="service animate__animated animate__zoomIn">
          <FaPaintBrush size={50} color="#e45447" className="service-icon" />
          <h3>Graphic Design</h3>
          <ul>
            <li>Official logos and branding</li>
            <li>Brochures, flyers, and posters</li>
            <li>Digital and print newsletters</li>
            <li>Social media graphics</li>
            <li>Event programs and invitations</li>
            <li>Banners and signage</li>
            <li>Infographics and data visualization</li>
          </ul>
        </div>

        {/* Training Service */}
        <div className="service animate__animated animate__zoomIn">
          <FaChalkboardTeacher
            size={50}
            color="#e45447"
            className="service-icon"
          />
          <h3>Multimedia Production Training</h3>
          <ul>
            <li>Camera operation and best settings</li>
            <li>Video editing and post-production</li>
            <li>Photography techniques and best practices</li>
            <li>Graphic design software tutorials</li>
            <li>Social media content creation</li>
            <li>Effective storytelling strategies</li>
            <li>Aerial photography and videography using drones</li>
          </ul>
        </div>

        {/* Special VR Service */}
        <div className="service animate__animated animate__zoomIn">
          <FaVrCardboard size={50} color="#e45447" className="service-icon" />
          <h3>Special Services</h3>
          <ul>
            <li>
              VR Tours: Create immersive virtual reality experiences for
              prospective families, offering a unique way to explore school
              facilities and programs from anywhere.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
