import { Box, Button, Modal, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { GraphicDesignArr } from "../constants/GraphicImages";

const GraphicDesign = () => {
  const [open, setOpen] = React.useState(false);
    const theme = useTheme();
  const [selectedImage, setSelectedImage] = React.useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ paddingTop: "8rem", justifyContent: "center" }}>
      <span
        className="section-title"
        style={{
          paddingBottom: "10px !important",
          paddingLeft: "15px",
          fontSize: "2.5rem",
        }}
      >
        Graphic Design
      </span>
      <div
        style={{
          paddingTop: "20px",
          paddingLeft: "15px",
          paddingRight: "15px",
          display: "flex",
          flexWrap: "wrap",
          gap: "50px",
        }}
      >
        {GraphicDesignArr.map((image, index) => (
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            gap={2}
            sx={{
              "@media (min-width:600px)": {
                "& > *": {
                  flex: "0 1 calc(33.333% - 16px)",
                },
              },
              "@media (min-width:900px)": {
                "& > *": {
                  flex: "0 1 calc(33.333% - 16px)",
                },
              },
              "@media (min-width:1200px)": {
                "& > *": {
                  flex: "0 1 calc(25% - 16px)",
                },
              },
            }}
          >
            <img
              src={image}
              alt={`Photo ${index + 1}`}
              height={isMobile ? "100%" : 200}
              width={"100%"}
              className="gallery-image"
              style={{
                width: "100%",
                cursor: "pointer",
                borderRadius: "4px",
                objectFit: "contain",
              }}
              onClick={() => handleOpen(image)}
            />
          </Box>
        ))}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "800px",
            maxHeight: "600px",
            overflow: "auto",
            backgroundColor: "white",
            padding: "20px",
            border: "1px solid #ccc",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: "100%", borderRadius: "4px" }}
          />
          <Button
            onClick={handleClose}
            color="primary"
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};


export default GraphicDesign