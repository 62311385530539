import "animate.css"; // For creative animations
import React from "react";

import { Contact } from "../Contact";
import HomeLogos from "./HomeLogos";
import Photos from "./HomePhotos";
import Videos from "./HomeVideos";
import { Intro } from "./Intro";
import Services from "./Services";
export const Home = () => (
  <div className="App">
    <Intro />
    <Services />
    <Videos />
    <Photos />  
    <HomeLogos />
    <Contact />
  </div>
);
