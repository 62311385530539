import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
const Videos = () => {
  const videoIds = [
     "P9cAuYIjckM",
    "VSneE26sOnk",
    "WYnoLzCyNxg",
    "FBipwGLRHRY",
    "bv5gjWdnMdo",
    "rVtJ6KFxwwo",
    "5gvOZnbIDHE",
    "i8nFkWxJHj8",
    "qEkjv4voP08",
  ];

  const [videos, setVideos] = useState([]);
  const [showMore] = useState(false);
 const navigate = useNavigate(); 
  useEffect(() => {
    const fetchVideoThumbnails = () => {
      const videoData = videoIds.map((videoId) => ({
        videoId,
        thumbnailUrl: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
        showVideo: false,
      }));
      setVideos(videoData);
    };
    fetchVideoThumbnails();
  }, []);

  const handleVideoClick = (videoId) => {
    const newVideos = videos.map((video) => ({
      ...video,
      showVideo: video.videoId === videoId ? true : video.showVideo,
    }));
    setVideos(newVideos);
  };


  return (
    <section id="services" className="services">
      <h2 className="animate__animated animate__fadeInUp">Videos</h2>
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          gap={2}
          sx={{
            "@media (min-width:600px)": {
              "& > *": {
                flex: "0 1 calc(33.333% - 16px)", // 3 cards per row on medium screens (sm)
              },
            },
            "@media (min-width:900px)": {
              "& > *": {
                flex: "0 1 calc(33.333% - 16px)", // 3 cards per row on large screens (md)
              },
            },
            "@media (min-width:1200px)": {
              "& > *": {
                flex: "0 1 calc(33.333%- 16px)", // 4 cards per row on extra large screens (lg)
              },
            },
          }}
        >
          {videos.slice(0, showMore ? videos.length : 6).map((video) => (
            <Card
              key={video.videoId}
              sx={{
                width: "100%", // Fixed width
                height: "220px", // Fixed height
                boxSizing: "border-box",
              }}
            >
              {video.showVideo ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <iframe
                    src={`https://www.youtube.com/embed/${video.videoId}?autoplay=1`}
                    frameBorder="0"
                    allowFullScreen
                    width="100%"
                    height="100%"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={video.thumbnailUrl}
                    alt="YouTube Thumbnail"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      cursor: "pointer",
                     
                    }}
                    onClick={() => handleVideoClick(video.videoId)}
                  />
                  <Box
                    position="absolute"
                    top="35%"
                    left="40%"
                    transform="translate(-50%, -50%)"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PlayArrowIcon
                      fontSize="large"
                      sx={{
                        fontSize: "4rem",
                        color: "white",
                        background: "rgba(0, 0, 0, 0.5)",
                        borderRadius: "50%",
                        padding: "0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={() => handleVideoClick(video.videoId)}
                    />
                  </Box>
                </Box>
              )}
              <CardContent>
                <Typography variant="subtitle1" component="div">
                  Bryan ISD
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        <Box display="flex" justifyContent="center" marginTop={3}>
          <button className="cta-button" onClick={() => navigate("/videos")} >{"See more"}</button>
        </Box>
      </div>
    </section>
  );
};

export default Videos;
