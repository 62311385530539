import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const Videos = () => {
const videoIds = [
  "P9cAuYIjckM",
  "ky4och-nxDE",
  "VSneE26sOnk",
  "WWu4wUKMAAI",
  "WYnoLzCyNxg",
  "FBipwGLRHRY",
  "bv5gjWdnMdo",
  "rVtJ6KFxwwo",
  "5gvOZnbIDHE",
  "i8nFkWxJHj8",
  "qEkjv4voP08",
  "u3b0KpzpzPQ",
  "ueSft3u1ji0",
  "rbedeSkzQMI",
  "i2VkGyv99Cw",
  "0C7DFmvHK5I",
  "geIngdSgPzc",
  "X7wzGJ9BirI",
  "IdmBXK_KyD8",
  "kdMERcDUQCE",
  "726IRU-I_vI",
  "MCyS_xZo0wo",
  "tY1mA_U7kOw",
  "xr5-Y-miRbo",
  "l_KdFksH9-I",
  "54mlBHFFh74",
];
  const [videos, setVideos] = useState([]);

  useEffect(() => {
const fetchVideoThumbnails = () => {
  const videoData = videoIds.map((videoId) => ({
    videoId,
    thumbnailUrl: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
    fallbackThumbnailUrl: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
    showVideo: false,
  }));

  setVideos(videoData);
};


    fetchVideoThumbnails();
  }, []);

  const handleVideoClick = (videoId) => {
    const newVideos = videos.map((video) => ({
      ...video,
      showVideo: video.videoId === videoId ? true : video.showVideo,
    }));
    setVideos(newVideos);
  };

  return (
    <div style={{ paddingTop: "8.5rem" }}>
      <span
        className="section-title"
        style={{
          paddingBottom: "10px !important",
          paddingLeft: "15px",
          fontSize: "2.5rem",
        }}
      >
        Videos
      </span>
      <div
        style={{
          paddingTop: "20px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          gap={2}
          style={{ paddingBottom: "8.5rem" }}
          sx={{
            // Adjusting layout based on screen size, keeping cards fixed width and height
            "@media (min-width:600px)": {
              "& > *": {
                flex: "0 1 calc(33.333% - 16px)", // 3 cards per row on medium screens (sm)
              },
            },
            "@media (min-width:900px)": {
              "& > *": {
                flex: "0 1 calc(33.333% - 16px)", // 3 cards per row on large screens (md)
              },
            },
            "@media (min-width:1200px)": {
              "& > *": {
                flex: "0 1 calc(25% - 16px)", // 4 cards per row on extra large screens (lg)
              },
            },
          }}
        >
          {videos.map((video) => (
            <Card
              key={video.videoId}
              sx={{
                width: "100%", // Fixed width
                height: "200px", // Fixed height
                boxSizing: "border-box",
              }}
            >
              {video.showVideo ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <iframe
                    src={`https://www.youtube.com/embed/${video.videoId}?autoplay=1`}
                    frameBorder="0"
                    allowFullScreen
                    width="100%"
                    height="100%"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={video.thumbnailUrl}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = video.fallbackThumbnailUrl; // Use fallback thumbnail
                    }}
                    alt="YouTube Thumbnail"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    onClick={() => handleVideoClick(video.videoId)}
                  />

                  <Box
                    position="absolute"
                    top="35%"
                    left="40%"
                    transform="translate(-50%, -50%)"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PlayArrowIcon
                      fontSize="large"
                      sx={{
                        fontSize: "4rem",
                        color: "white",
                        background: "rgba(0, 0, 0, 0.5)",
                        borderRadius: "50%",
                        padding: "0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={() => handleVideoClick(video.videoId)}
                    />
                  </Box>
                </Box>
              )}
              <CardContent>
                <Typography variant="subtitle1" component="div">
                  Bryan ISD
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>
    </div>
  );
};

export default Videos;
